<template>
	<div class="hidden"></div>
</template>

<script>
	import i18n from "@/i18n";

	export default {
		name: "PoliciesDeleter",
		props: {
			selectedMember: {
				type: Object,
				default: null
			},
			appId: {
				type: Number,
				required: true
			},
			type: {
				type: String,
				default: "user"
			}
		},
		watch: {
			selectedMember: function (newVal, oldVal) {
				if (newVal && oldVal !== newVal) {
					this.confirmDelAccess();
				}
			}
		},
		methods: {
			// permet d'ouvrir un popup (dialog) pour confirmer la suppression d’un utilisateur
			confirmDelAccess() {
				this.$vs.dialog({
					type: "confirm",
					color: "danger",
					title: i18n.t(this.type === "acces" ? "Supprimer l’accès" : "Supprimer l’utilisateur"),
					text: i18n.t("Êtes-vous certain de vouloir supprimer l’accès de ...", {
						firstname: this.selectedMember?.firstname ?? "",
						lastname: this.selectedMember?.lastname ?? ""
					}),
					accept: this.deleteAccess,
					cancel: this.canceldDeleteAccess,
					acceptText: i18n.t("Oui, supprimer l’accès"),
					cancelText: i18n.t("Non")
				});
			},
			canceldDeleteAccess() {
				this.$emit("done", false);
			},
			// permet de supprimer l’utilisateur qui est référencé dans l'objet this.selectedMember via DELETE API
			deleteAccess: function () {
				const payload = {
					appId: this.appId,
					userId: this.selectedMember.id
				};

				this.$store
					.dispatch("deleteAccess", payload)
					.then(async () => {
						this.$vs.notify({
							title: i18n.t(this.type === "acces" ? "Accès supprimé" : "Utilisateur supprimée !"),
							text: i18n.t(
								this.type === "acces" ? "Accès bien supprimé" : "L’utilisateur a bien été supprimé",
								{
									firstname: this.selectedMember?.firstname ?? "",
									lastname: this.selectedMember?.lastname ?? ""
								}
							),
							color: "success",
							iconPack: "feather",
							icon: "icon-check",
							time: 5000
						});
						await this.$store.dispatch("getAccountingFirm");
						this.$emit("done", true);
					})
					.catch(error => {
						console.error(error);

						this.$vs.notify({
							title: i18n.t("Impossible de supprimer l’utilisateur"),
							text: i18n.t("L’utilisateur n’a pas été supprimée", {
								firstname: this.selectedMember?.firstname ?? "",
								lastname: this.selectedMember?.lastname ?? ""
							}),
							color: "danger",
							iconPack: "feather",
							icon: "icon-alert-triangle",
							time: 15000
						});
						this.$emit("done", false);
					});
			}
		}
	};
</script>
